import { Inject, Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { catchError, map, Observable, of, ReplaySubject, take, tap } from 'rxjs';
import {
    frontPageSettingsStatus,
    ICrewMissingDocumentSummary,
    IFrontPageSettings,
    IUserProfile,
    IUserSeaServiceYearly,
    IUserSubstituteCandidacy,
    User
} from '../models/user';
import { DOCUMENT } from '@angular/common';
import { environment } from '@env/environment';
import { AuthHttpService } from "@appcore/services/auth-http.service";
import { AppError } from "@appcore/models/error";
import {
    IActivityPeriodCrew,
    ICrew,
    ICrewAddress,
    ICrewLeave,
    ICrewOnCall,
    ICrewOnVesselLightWithRole,
    ICrewVesselRole,
    IUserActivityPeriodInfo,
    IUserActivityPeriods,
    IUserPeriod,
    IUserRelation,
    IUserStatus,
    IVessel,
    IVesselBalanceSheet,
    IVesselDetails,
    IVesselFisheryAggregated,
    IVesselSimple
} from "@appcore/models";
import { TranslocoService } from "@ngneat/transloco";
import { UserLanguageDialogComponent } from "../../modules/user/components/user-language-dialog.component";
import { MatDialog, MatDialogState } from "@angular/material/dialog";
import { IUserFile } from "@appcore/models/user-file";
import {
    ICrewActivity,
    ICrewActivityActivityPeriodOverview,
    ICrewActivityLogSummary,
    ISlimCrewActivity
} from "@appcore/models/crew-activity";
import { blobDownloadHelper, extractDate } from "@appcore/utils";
import { ICrewVesselAssignment, ICrewVesselAssignmentDetails } from '@appcore/models/crewVesselAssignment';
import { IAssignmentParameter } from '@appcore/models/assignmentParameter';
import { CreateUserDocumentDto, IUserDocumentCollection } from '@appcore/models/document';
import { ITravelAllowanceTransport } from "@appcore/models/travel-allowance";
import { DeviceDetectorService } from "ngx-device-detector";
import { IUserSubstituteRotationSlot } from "@appcore/models/talentAndPlacement";
import { IWallet } from "@appcore/models/payroll";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    isAuthenticated$: Observable<boolean>;
    private user$: Observable<User | null | undefined>;
    private dialogRef: any;
    private _userProfile$ = new ReplaySubject<IUserProfile | null>(1);


    private currentUserPeriodInitialized: boolean;
    private _currentUserPeriod$ = new ReplaySubject<IUserPeriod | undefined>(1);
    private _userStatus$ = new ReplaySubject<IUserStatus | undefined>(1);
    private _frontPageSettings$ = new ReplaySubject<IFrontPageSettings | undefined>(1);
    private _frontPageSettingsStatus$ = new ReplaySubject<frontPageSettingsStatus | undefined>(1);

    constructor(
        private auth: AuthService,
        @Inject(DOCUMENT) private doc: Document,
        private authHttp: AuthHttpService,
        private translocoService: TranslocoService,
        public dialog: MatDialog,
        private deviceService: DeviceDetectorService) {

        this.isAuthenticated$ = this.auth.isAuthenticated$;
        this.user$ = this.auth.user$
            .pipe(
                map(c => {
                    return new User(c);
                }),
                catchError(() => {
                    return of(null)
                })
            );

        this.isAuthenticated$.subscribe(authed => {
                if (!authed) {
                    this._userProfile$.next(null);
                } else {
                    this.refreshProfile();
                }
            }
        );
    }

    get userProfile$() {
        return this._userProfile$.asObservable();
    }
    getUser(id: string) {
        return this.authHttp.get<User>(`/api/user/${id}`).pipe(map(c => c.body))
    }

    get accessToken$() {
        return this.auth.getAccessTokenSilently(environment.tokenOptions)
            .pipe(catchError(() => {
                return of('')
            }));
    }

    /*getPermissions(): IPermission[] {
        this.userProfile$.pipe(take(1)).subscribe(res => {
            if (!res)
                return []

            return res?.permissions;
        });
    }*/


    get currentUserPeriod$(): Observable<IUserPeriod | undefined> {
        return this._currentUserPeriod$.asObservable();
    }

    get userStatus$(): Observable<IUserStatus | undefined> {
        return this._userStatus$.asObservable();
    }

    refreshStatus(companyId: number) {
        return this.authHttp.get<IUserStatus>(`/api/user/status/${companyId}`)
            .pipe(map(c => c.body)).subscribe((status) => {
                this._userStatus$.next(status ?? undefined);
                if(!this.currentUserPeriodInitialized) {
                    this._currentUserPeriod$.next(status?.currentUserPeriod);
                    this.currentUserPeriodInitialized = true;
                }
            });
    }

    hasAccess(anyOf: string[]) {
        return this.userProfile$
            .pipe(
                map(profile => profile != null &&
                    anyOf.some(c => profile.access.indexOf(c) !== -1)),
                catchError(() => {
                    return of(false);
                })
            );
    }

    login() {
        this.auth.loginWithRedirect();
    }

    logout(): void {
        this.auth.logout({returnTo: this.doc.location.origin});
    }

    get defaultRoute() {
        return this.userProfile$
            .pipe(
                map(profile => {
                    const access = profile?.access ?? [];
                    if (!profile?.language && (!this.dialogRef || this.dialogRef.getState() !== MatDialogState.OPEN)) {
                        this.dialogRef = this.dialog.open(UserLanguageDialogComponent, {
                            width: '400px'
                        });
                    }
                    this.translocoService.setActiveLang(profile?.language && profile.language !== "" ? profile.language : 'en');
                    if(access.indexOf('org') !== -1) {
                        const companies = profile?.companies ?? [];

                        let localStorageShippingCompanyId = localStorage.getItem("shippingCompanyId");
                        let hasAccessToLocalStorageShippingCompany =
                            companies.some(c => c.shippingCompanyId === parseInt(localStorageShippingCompanyId ?? "-1"));

                        if (localStorageShippingCompanyId && hasAccessToLocalStorageShippingCompany)
                            return `/dashboard/organization/view/${localStorageShippingCompanyId}`;
                        else if (companies.length > 0)
                            return `/dashboard/organization/view/${companies[0].shippingCompanyId}`;
                    }

                    if (!this.deviceService.isMobile()) {
                        const masterViewCompanies = profile?.companies.filter(c => c.masterViewAccess) ?? [];
                        if (masterViewCompanies.length > 0) {
                            return `/dashboard/master-view/${masterViewCompanies[0].shippingCompanyId}`
                        }
                    }
                    return access.length > 0  ? '/dashboard' : '/noaccess';
                }),
                catchError(() => {
                    return of('/noaccess');
                })
            );
    }

    $status(company: number) {
        return this.authHttp.get<IUserStatus>(`/api/user/status/${company}`)
            .pipe(map(c => c.body));
    }

    $userPeriod(company: number, vesselId: string, periodCompoundId: string) {
        return this.authHttp.get<IUserPeriod>(`/api/user/status/${company}/${vesselId}/${periodCompoundId}`)
            .pipe(map(c => c.body));
    }

    $crewId(shippingCompanyId: number) {
        return this.authHttp.get<number>(`/api/user/crewId/${shippingCompanyId}`).pipe(map(c => c.body))
    }

    $changeVesselUserPeriod(company: number, vesselId: string) {
        return this.authHttp.get<IUserPeriod>(`/api/user/status/${company}/${vesselId}`)
            .pipe(map(c => c.body)).subscribe((period) => {
                this._currentUserPeriod$.next(period!);
            });
    }

    $changeShippingCompany(company: number) {
        return this.authHttp.put(`/api/user/changeChosenShippingCompany/${company}`, {});
    }

    fisheryAggregated$(id: string, companyId: string, year: string) {
        return this.authHttp.get<IVesselFisheryAggregated[]>(`/api/user/fishery/aggregated/${companyId}/${id}/${year}`)
            .pipe(map(c => c.body))
    }

    fisheryQuotaYears$(vesselId: string, companyId: number) {
        return this.authHttp.get<number[]>(`/api/user/fishery/quota-years/${companyId}/${vesselId}`)
            .pipe(map(c=>c.body))
    }

    seagoingServiceYearly$(){
        return this.authHttp.get<IUserSeaServiceYearly[]>(`/api/user/seagoing-service/yearly`)
            .pipe(map(c => c.body))
    }

    exportSeagoingService$(){
        const url = `/api/user/seagoing-service/excel`;
        return this.authHttp.get(url, 'blob');
    }

    $activityPeriods(company: number) {
        return this.authHttp.get<IUserActivityPeriods>(`/api/user/activity-periods/${company}`)
            .pipe(map(c => c.body));
    }

    $activityPeriod(company: number, activityPeriodId: number) {
        return this.authHttp.get<IUserActivityPeriodInfo>(`/api/user/activity-periods/${company}/${activityPeriodId}`)
            .pipe(map(c => c.body));
    }

    updateProfile(profile: {name: string, email: string, phone: string}) {
        return this.authHttp.put(`/api/user`, profile)
            .pipe(
                map(c => c.body),
                tap(() => {
                    this.refreshProfile();
                }));
    }

    $crewAddress() {
        return this.authHttp.get<ICrewAddress>(`/api/user/address`)
            .pipe(map(c => c.body));
    }

    updateCrewAddress(payload: {address: string, postPlace: string, postCode: string}) {
        return this.authHttp.put(`/api/user/address`, payload);
    }

    getUserRelations() {
        return this.authHttp.get<IUserRelation[]>(`/api/user/user-relations`).pipe(map(c => c.body || []));
    }

    updateUserRelations(relations: IUserRelation[]) {
        return this.authHttp.post<void>(`/api/user/user-relations`, relations);
    }

    getCrewLeave() {
        return this.authHttp.get<ICrewLeave[]>(`/api/user/crew-leave`).pipe(map(c => c.body || []));
    }

    getUserDocuments() {
        return this.authHttp.get<IUserDocumentCollection>(`/api/user/user-documents`).pipe(map(c => c.body!));
    }

    createUserDocument(dto: CreateUserDocumentDto) {
        const formData = new FormData();
        formData.append('file', dto.file);
        formData.append('fileType', dto.fileType);
        formData.append('validFrom', dto.validFrom ? this.dateOnly(dto.validFrom.toString()) : "");
        formData.append('validTo', dto.validTo ? this.dateOnly(dto.validTo.toString()) : "");
        formData.append('description', dto.description);
        return this.authHttp.post('/api/user/create-user-document', formData)
            .pipe(map(c => c.body));
    }

    updateUserLanguage(payload: {language: string}) {
        return this.authHttp.put(`/api/user/language`, payload);
    }

    updateUserSubstituteCandidacies(candidacies:IUserSubstituteCandidacy[]) {
        return this.authHttp.put<IUserSubstituteCandidacy[]>(`/api/user/substitute/candidacies`, candidacies);
    }

    getUserOpenSubstituteRotationSlots(){
        return this.authHttp.get<IUserSubstituteRotationSlot[]>(`/api/user/substitute/rotations/open`).pipe(map(c=>c.body));
    }

    getUserAssignedSubstituteRotationSlots(){
        return this.authHttp.get<IUserSubstituteRotationSlot[]>(`/api/user/substitute/rotations/assigned`).pipe(map(c=>c.body));
    }

    updateUserSubstituteRotationSlot(slot:IUserSubstituteRotationSlot) {
        return this.authHttp.put<IUserSubstituteRotationSlot>(`/api/user/substitute/rotations/${slot.id}`, slot).pipe(map(c=>c.body));
    }

    updateProfilePicture(picture: File) {
        const formData = new FormData();
        formData.append('picture', picture);
        return this.authHttp.post('/api/user/profilePicture', formData)
            .pipe(map(c => c.body));
    }

    filesByCrewOnVesselId(companyId: number, crewOnVesselId: number) {
        return this.authHttp.post<IUserFile[]>(
            `/api/user/files/${companyId}`,
            {
                "CrewOnVesselId": crewOnVesselId
            }
        ).pipe(map(c => c.body));
    }

    filesByVessel(companyId: number, vesselId: string) {
        return this.authHttp.post<IUserFile[]>(
            `/api/user/files/${companyId}`,
            {
                "VesselId": vesselId
            }
        ).pipe(map(c => c.body));
    }

    file(companyId: number, fileId: string) {
        return this.authHttp.get<IUserFile>(
            `/api/user/files/${companyId}/${fileId}`
        ).pipe(map(c => c.body));
    }

    deleteFile(shippingCompanyId: number, fileId: string) {
        return this.authHttp.delete(
            `/api/user/files/${shippingCompanyId}/${fileId}`
        ).pipe(map(c => c.body));
    }

    setFileMetaData(companyId: number, fileId: string, type: string, value: string) {
        return this.authHttp.post<IUserFile[]>(
            `/api/user/files/${companyId}/${fileId}/meta`,
            {type: type, value: value}
        ).pipe(map(c => c.body));
    }

    setMultipleFileMetaData(companyId: number, fileId: string, values: { type: string, value: string }[]) {
        return this.authHttp.post<IUserFile[]>(
            `/api/user/files/${companyId}/${fileId}/meta-multiple`,
            values
        ).pipe(map(c => c.body));
    }

    $activities(
        company: number,
        vesselId: string,
        fromDate: string,
        toDate: string,
        showFutureDays: boolean = false
    ) {
        return this.authHttp.post<ICrewActivityLogSummary>(`/api/user/crew-activity/${company}/table`, {
            "VesselId": vesselId,
            "FromDate": fromDate,
            "ToDate": toDate,
            "ShowFutureDays": showFutureDays,
        }).pipe(map(c => c.body));
    }

    createActivity(
        company: number,
        vesselId: string,
        fromDate: string,
        toDate: string,
        type: string,
        note: string,
        hourTypeId?: number | null,
        crewOnVesselId?: number | null
    ) {
        return this.authHttp.post(`/api/user/crew-activity/${company}/create`, {
            "VesselId": vesselId,
            "FromDate": fromDate,
            "ToDate": toDate,
            "ActivityType": type,
            "Note": note,
            "HourTypeId": hourTypeId,
            "CrewOnVesselId": crewOnVesselId
        });
    }

    massCreateActivity(
        company: number,
        vesselId: string,
        fromDate: string,
        toDate: string,
        activities: ISlimCrewActivity[]
    ) {
        return this.authHttp.post(`/api/user/crew-activity/${company}/mass-create`, {
            "VesselId": vesselId,
            "FromDate": fromDate,
            "ToDate": toDate,
            "CrewActivityLogs": activities,
        });
    }

    clearPeriodActivity(
        company: number,
        fromDate: string,
        toDate: string
    ) {
        return this.authHttp.post(`/api/user/crew-activity/${company}/clear-activity`, {
            "FromDate": fromDate,
            "ToDate": toDate
        });
    }

    createOfficerActivity(
        company: number,
        vesselId: string,
        crewId: number,
        fromDate: string,
        toDate: string,
        type: string,
        note: string
    ) {
        return this.authHttp.post<ICrewActivity[]>(`/api/user/crew-activity/officer/${company}/create`, {
            "VesselId": vesselId,
            "CrewId": crewId,
            "FromDate": fromDate,
            "ToDate": toDate,
            "ActivityType": type,
            "Note": note
        });
    }

    deleteActivity(company: number, id: number) {
        return this.authHttp.delete<boolean>(`/api/user/crew-activity/${company}/delete/${id}`);
    }

    copyActivityFromYesterday(
        company: number,
        vesselId: string,
        fromDate: string,
        toDate: string,
        type: string
    ) {
        return this.authHttp.post<ICrewActivity[]>(`/api/user/crew-activity/${company}/copy-from-yesterday`, {
            "VesselId": vesselId,
            "FromDate": fromDate,
            "ToDate": toDate,
            "ActivityType": type
        }).pipe(map(c => c.body), catchError(() => {
            return of(null);
        }));
    }

    fillFromWorkingHours(company: number, vesselId: string, fromDate: string, toDate: string, crewOnVesselId: number) {
        return this.authHttp.post(`/api/user/crew-activity/${company}/fill-from-working-hours`, {
            "VesselId": vesselId,
            "FromDate": fromDate,
            "ToDate": toDate,
            "CrewOnVesselId": crewOnVesselId
        }).pipe(map(c => c.body), catchError(() => {
            return of(null);
        }));
    }

    downloadSdirReport(company: number, vesselId: string, fromDate: string, toDate: string) {
        return this.authHttp.post(`/api/user/crew-activity/${company}/sdir-report`, {
            "VesselId": vesselId,
            "FromDate": fromDate,
            "ToDate": toDate
        }, 'blob')
    }

    officerGetActivityOnVessel(company: number, vesselId: string, from: string, to: string) {
        return this.authHttp.get<ICrewActivityActivityPeriodOverview>(
            `/api/user/vessel/officer/${company}/${vesselId}/${from}/${to}`
        ).pipe(map(c => c.body));
    }

    $activitiesOfficer(company: number, vesselId: string, crewId: number, fromDate: string, toDate: string) {
        return this.authHttp.post<ICrewActivityLogSummary>(
            `/api/user/crew-activity/officer/${company}/table/`,
            {
                "VesselId": vesselId,
                "FromDate": fromDate,
                "ToDate": toDate,
                "CrewId": crewId,
            }).pipe(map(c => c.body));
    }

    downloadSdirReportOfficer(
        company: number,
        vesselId: string,
        crewId: number,
        fromDate: string,
        toDate: string
    ) {
        return this.authHttp.post(`/api/user/crew-activity/officer/${company}/sdir-report`, {
            "VesselId": vesselId,
            "FromDate": fromDate,
            "ToDate": toDate,
            "CrewId": crewId,
        }, 'blob')
            .subscribe(blob => blobDownloadHelper(blob));
    }

    approveActivity(
        company: number,
        vesselId: string,
        crewId: number,
        fromDate: string,
        toDate: string,
        status: string
    ) {
        return this.authHttp.post<ICrewActivity[]>(`/api/user/crew-activity/officer/${company}/approve`, {
            "VesselId": vesselId,
            "CrewId": crewId,
            "FromDate": fromDate,
            "ToDate": toDate,
            "Status": status,
        }).pipe(map(c => c.body), catchError(() => {
            return of(null);
        }));
    }

    getCrewOnVessel(company: number, crewOnVesselId: number) {
        return this.authHttp.get<IActivityPeriodCrew>(
            `/api/user/${company}/crew-on-vessel/${crewOnVesselId}`)
    }

    getProfileByEmail(email: string) {
        return this.authHttp.get<ICrew>(`/api/user/email/${email}`);
    }

    checkCrewCanCheckIn(company: number) {
        return this.authHttp.get<boolean>(`/api/user/${company}/crew-on-vessel/can-check-in`);
    }

    crewCanCheckInDefaultToDate(company: number, vesselId: string, fromDate?: string) {
        if (!fromDate) {
            fromDate = new Date().toISOString();
        }

        return this.authHttp.get<CheckInDefaultToDateStatus>(
            `/api/user/${company}/crew-on-vessel/can-check-in/${vesselId}/default-to-date-for/${extractDate(fromDate)}`
        );
    }

    crewCheckIn(
        company: number,
        vesselRoleId: number,
        vesselId: string,
        fromDate: string,
        toDate: string
    ) {
        return this.authHttp.post(`/api/user/${company}/crew-on-vessel/check-in`, {
            "VesselId": vesselId,
            "VesselRoleId": vesselRoleId,
            "FromDate": fromDate,
            "ToDate": toDate,
        });
    }

    crewCheckOut(
        company: number,
        crewOnVesselId: number,
        date: string,
    ) {
        return this.authHttp.post(`/api/user/${company}/crew-on-vessel/check-out`, {
            "CrewOnVesselId": crewOnVesselId,
            "Date": date,
        });
    }

    crewContinueCrewOnVessel(
        company: number,
        crewOnVesselId: number,
        toDate: string
    ) {
        return this.authHttp.post(`/api/user/${company}/crew-on-vessel/continue`, {
            "CrewOnVesselId": crewOnVesselId,
            "ToDate": toDate,
        });
    }

    crewVesselRoles(company: number, vesselId: string) {
        return this.authHttp.get<ICrewVesselRole[]>(
            `/api/user/${company}/vessels/${vesselId}/crew-vessel-roles`
        );
    }

    crewVessels(company: number) {
        return this.authHttp.get<IVesselSimple[]>(
            `/api/user/${company}/vessels/`
        );
    }

    addTravelAllowanceTransport(
        company: number,
        vesselId: string,
        crewId: number,
        travelAllowanceTransport: ITravelAllowanceTransport
    ) {
        return this.authHttp.post<ITravelAllowanceTransport>(
            `/api/user/travel-allowance-transport/${company}/${vesselId}/${crewId}`,
            travelAllowanceTransport
        );
    }

    updateTravelAllowanceTransport(
        company: number,
        travelAllowanceTransport: ITravelAllowanceTransport
    ) {
        return this.authHttp.post<ITravelAllowanceTransport>(
            `/api/user/travel-allowance-transport/${company}/update`,
            travelAllowanceTransport
        );
    }

    getTravelAllowanceTransports(company: number, crewOnVesselId: number) {
        return this.authHttp.get<ITravelAllowanceTransport[]>(
            `/api/user/travel-allowance-transport/${company}/${crewOnVesselId}`,
        ).pipe(map(c => c.body));
    }

    deleteTravelAllowanceTransport(company: number, id: number) {
        return this.authHttp.delete(
            `/api/user/travel-allowance-transport/${company}/delete/${id}`,
        ).pipe(map(c => c.body));
    }

    private refreshProfile(){
        this.authHttp.get('/api/user')
            .pipe(
                take(1),
                map(c => c.body),
            )
            .subscribe({
                next: userProfile => {
                    this._userProfile$.next(<IUserProfile>userProfile);
                },
                error: err => {
                    this._userProfile$.error(new AppError(err));
                }
            });
    }

    getVesselAssignments(shippingCompanyId: number, vesselId: string) {
        return this.authHttp.get<ICrewVesselAssignment[]>(`/api/user/vessel-assignment/${shippingCompanyId}/${vesselId}`);
    }

    getVesselAssignmentDetails(shippingCompanyId: number, vesselAssignmentId: number) {
        return this.authHttp.get<ICrewVesselAssignmentDetails>(`/api/user/vessel-assignment-details/${shippingCompanyId}/${vesselAssignmentId}`);
    }

    updateVesselAssignmentComments(shippingCompanyId: number, vesselAssignmentId: number, newComments: string) {
        return this.authHttp.patch<string>( `/api/user/vessel-assignment-details/${shippingCompanyId}/${vesselAssignmentId}/comments`, { newComments });
    }

    updateVesselAssignmentLog(shippingCompanyId: number, vesselAssignmentId: number, newLog: IAssignmentParameter[]) {
        return this.authHttp.patch<string>( `/api/user/vessel-assignment-details/${shippingCompanyId}/${vesselAssignmentId}/log`, newLog);
    }

    getCrewList(
        shippingCompanyId: number, vesselID: string, crewOnVesselId: number, fromDate: string, toDate: string
    ) {
        return this.authHttp.post<ICrewOnVesselLightWithRole[]>(
            `/api/user/crew-on-vessel-list/${shippingCompanyId}`,
            {
                "CrewOnVesselId": crewOnVesselId,
                "VesselId": vesselID,
                "FromDate": fromDate,
                "ToDate": toDate,
            }
        );
    }

    getVessel(shippingCompanyId: number, vesselId: string) {
        return this.authHttp.get<IVesselDetails>(`/api/user/vessel/${shippingCompanyId}/${vesselId}`)
            .pipe(map(c => c.body));
    }

    get frontPageSettings$() {
        return this._frontPageSettings$.asObservable();
    }

    get frontPageSettingsStatus$() {
        return this._frontPageSettingsStatus$.asObservable();
    }

    async frontPageSettings(shippingCompanyId: number) {
        return this.authHttp.get<IFrontPageSettings>(`/api/shared/shipping-company/${shippingCompanyId}/user-settings`)
            .pipe(map(c => c.body))
            .subscribe({
                next: (settings) => {
                    this._frontPageSettings$.next(settings ?? undefined);
                    this._frontPageSettingsStatus$.next(frontPageSettingsStatus.GET_SUCCESS);
                },
                error: () => {
                    this._frontPageSettings$.next(undefined);
                    this._frontPageSettingsStatus$.next(frontPageSettingsStatus.GET_ERROR);
                }
            });
    }

    previewFrontPageSettings(payload: IFrontPageSettings | undefined) {
        this._frontPageSettings$.next(payload);
    }

    updateFrontPageSettings(shippingCompanyId: number, payload: IFrontPageSettings) {
        return this.authHttp.post<IFrontPageSettings>(`/api/shared/shipping-company/${shippingCompanyId}/user-settings`, payload)
            .pipe(map(c => c.body)).subscribe({
                next: (settings) => {
                    this._frontPageSettings$.next(settings ?? undefined);
                    this._frontPageSettingsStatus$.next(frontPageSettingsStatus.POST_SUCCESS);
                },
                error: () => {
                    this._frontPageSettingsStatus$.next(frontPageSettingsStatus.POST_ERROR);
                }
            });
    }

    clearFrontPageSettingsStatus () {
        this._frontPageSettingsStatus$.next(frontPageSettingsStatus.CLEAR);
    }

    getCrewMissingDocumentsSummary(shippingCompanyId: number) {
        return this.authHttp.get<ICrewMissingDocumentSummary>(
            `/api/user/${shippingCompanyId}/missing-documents`
        );
    }

    getVesselsByEmployment() {
        return this.authHttp.get<IVessel[]>(`/api/user/vessel-employments`).pipe(map(c => c.body));
    }

    getCrewOnCall(shippingCompanyId: number) {
        return this.authHttp.get<ICrewOnCall[]>(
            `/api/user/shipping-company/${shippingCompanyId}/crew-on-call`
        );
    }

    balanceSheet$(shippingCompanyId: number, vesselId: string, compoundId: string) {
        const path = `/api/user/shipping-company/${shippingCompanyId}/balance-sheet/${vesselId}/settlement-period/${compoundId}`;
        return this.authHttp.get<IVesselBalanceSheet>(path).pipe(map(c => c.body));
    }

    getWallet(crewId: number, shippingCompanyId: number) {
        const path = `/api/user/shipping-company/${shippingCompanyId}/wallet/${crewId}`;
        return this.authHttp.get<IWallet>(path).pipe(map(c => c.body));
    }

    private dateOnly(date: string) {
        return date.toString().split('T')[0];
    }

    downloadPayslip(crewId: number, payrollSettlementPeriodId: number, shippingCompanyId: number) {
        return this.authHttp.get(`/api/user/shipping-company/${shippingCompanyId}/${crewId}/download-payslip/${payrollSettlementPeriodId}`, 'blob');
    }
}

export const halfHourBlockList: string[] = [
    "00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00",
    "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30",
    "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00",
    "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30",
    "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00",
    "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30",
    "21:00", "21:30", "22:00", "22:30", "23:00", "23:30", "24:00"
];

export interface CheckInDefaultToDateStatus {
    defaultToDate: Date;
    maxDate?: Date;
}
